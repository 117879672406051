<template>
    <section class="py-8 md:py-20">
      <div class="max-w-screen-2xl mx-auto px-4 flex relative z-10 flex-wrap  h-full ">
        <div class="w-full">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            {{name}}
          </h2>
        </div>
        <div class="w-full md:py-2 flex-col gap-8 flex">
          <div v-for="(hotel, index) of hotels" :key="index" >
            <Hotel :hotel="hotel"></Hotel>
          </div>

        </div>
      </div>  
    </section>

</template>

<script>
//import * as _ from 'lodash';
import Hotel from '@/components/hotels/Hotel.vue';
export default {
  name: 'HotelsCity',
  props: {
    hotels: Array,
    name: String
  },
  components: {
    Hotel
  },
  data () {
    return {
    }
  },
  async mounted () {
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
  },
  methods: {

  }

}
</script>
