<template>
  <div id="say-hotels">
    <main>
        <Header></Header>
        <div v-for="city of cities" :key="city.locationCode">
          <HotelsCity :hotels="city.hotels" :name="city.locationName"></HotelsCity>
        </div>
    </main>
    <Footer></Footer>
    <Transition>
        <BlockUI v-if="loading"></BlockUI>
    </Transition>

  </div>
</template>

<script>
import BlockUI from '@/components/tools/BlockUI.vue';
import Header from '@/components/hotels/Header.vue';
import HotelsCity from '@/components/hotels/HotelsCity.vue';
import Footer from '@/components/tools/Footer.vue';
import * as _ from 'lodash';
import '@/assets/css/main.scss';
const api = window.api;
export default {
  name: 'SayHotels',
  data() {
    return {
      showModal: false,
      loading: false,
      cities: [],
    }
  },
  computed: {
  },
  async mounted() {
    this.loading = true;
    api.get('/api/tpsql/trip_seller', this.$route.query, null, (r) => {
      if (r && r[0]) {
        this.$store.commit('setSeller', r[0]);
      }
    });
    let res = await api.get('/api/tpsql/trip_booking_hotels', this.$route.query);
    if (res) {
      let unique = _.uniqBy(res, 'LOCATION');
      this.cities = _.map(unique, (r) => {
          return {
            locationCode: r.LOCATION,
            locationName: r.LocationName,
            hotels: _.filter(res, (c) => {
                return c.LOCATION == r.LOCATION;
            })
          }
      })
      this.$store.commit('setBooking', res.booking)
      this.$store.commit('setQuery', this.$route.query);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
  components: {
    BlockUI,
    Header,
    HotelsCity,
    Footer
  }
}
</script>

<style scoped>
.trip-body {
  overflow: scroll;
}

.v-leave-active {
  transition: opacity 1s ease;
}


.v-leave-to {
  opacity: 0;
}
</style>
