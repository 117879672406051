<template>
        <div>
            <div class="blockUI blockOverlay" style="z-index: 1100; border: none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; background-color: #4b5563; opacity: 1; cursor: wait; position: fixed;">
            </div>
            <div class="blockUI blockMsg blockPage" style="z-index: 1111; position: fixed; padding: 0px; margin: 0px; width: 30%; top: 40%; left: 35%; text-align: center; color: #4b5563; border: 0px; cursor: wait;">
                <div class="openerp oe_blockui_spin_container" style="background-color: transparent;">
                    <div clas="flex justify-center">
                        <div class="mb-3">
                            <img src="@/assets/trip/img/logo.svg">
                        </div>
                        <div style="color:white; font-size: large;">
                            {{tr('We are preparing your trip')}}...
                        </div>
                        <div class="linear-activity">
                            <div class="indeterminate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'block-ui',
    methods: {
        tr (t) {
            return t;
        }
    },
}
</script>

<style scoped>
.processing-spin {
      animation-name: spin;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: steps(12);
}

.loader-spin img { 
    max-width: 120px !important;
    max-height: 120px !important;
    -webkit-animation: rotation 2s linear infinite;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.linear-activity {
    overflow: hidden;
    height: 4px;
    margin: 20px auto;
}

.indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
}

.indeterminate:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: white;
    animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: white;
    animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}

@keyframes indeterminate_second {
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
}


</style>
