<template>
  <Index ref="index"/>
</template>

<script>
import Index from './components/Index.vue'

const languages = {
  en: 'en',
  sp: 'es',
  de: 'de'
}

export default {
  name: 'App',
  data () {
    return {
      lang: 'en',
      langCode: 'en',
      translations: {}
    }
  },
  components: {
    Index
  },
  mounted() {
    // Get URL search params
    const urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get('Lang');
    if (!lang) lang = 'en';
    this.lang = lang.toLowerCase();
    this.langCode = languages[this.lang];
    this.$store.commit('setLang', lang);
    this.translations = require(`@/assets/languages/${this.lang}.json`);
  },
  methods: {
    tr (text) {
      if (!text) return text;
      try {
        if (this.translations[text]) return this.translations[text];
      } catch (err) {
          //console.log(err)  
      }
      return text
    }
  }
}
</script>