<template>
  <div class="form-material mt-3">
    <div class="row">
      <div class="form-group col-2">
        <div class="form-group form-default">
          <input v-model="bookingRef" class="form-control" type="text"/>
          <span class="form-bar">
          </span>
          <label class="float-label ml-1" :class="{'fill': bookingRef}">Booking Ref</label>
        </div>
      </div>
      <div class="form-group col-2">
        <div class="form-group form-default">
          <input v-model="agent" class="form-control" type="text"/>
          <span class="form-bar">
          </span>
          <label class="float-label ml-1" :class="{'fill': agent}">Agent</label>
        </div>
      </div>
      <div class="form-group col-1">
        <button type="button" class="btn btn-primary my-0" @click="find()" :disabled="processing">Find</button>
      </div>
      <div class="form-group col-5">
        <a :href="url" v-if="url" target="_blank">URL: {{ url }}</a>
      </div>
    </div>
    <div class="row" v-if="booking">
      <table class="table table-striped  table-sm table-bordered">
        <thead>
          <tr>
            <td>Room</td>
            <td>Price/Pax</td>
            <td>Cost/Pax</td>
            <td>Profit/Pax</td>
          </tr>
        </thead>
        <tbody v-if="booking.prices">
          <tr @click="showRows('Single')">
            <td>SGL</td>
            <td class="text-right">{{booking.prices.sgl_total_price.toFixed(2)}}</td>
            <td class="text-right">{{booking.sgl_total_cost.toFixed(2)}}</td>
            <td class="text-right">{{(booking.prices.sgl_total_price - booking.sgl_total_cost).toFixed(2)}}</td>
          </tr>
          <tr @click="showRows('Double')">
            <td>DBL</td>
            <td class="text-right">{{booking.prices.dbl_total_price.toFixed(2)}}</td>
            <td class="text-right">{{booking.dbl_total_cost.toFixed(2)}}</td>
            <td class="text-right">{{(booking.prices.dbl_total_price - booking.dbl_total_cost).toFixed(2)}}</td>
          </tr>
          <tr @click="showRows('Triple')">
            <td>TPL</td>
            <td class="text-right">{{booking.prices.tpl_total_price.toFixed(2)}}</td>
            <td class="text-right">{{booking.tpl_total_cost.toFixed(2)}}</td>
            <td class="text-right">{{(booking.prices.tpl_total_price - booking.tpl_total_cost).toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" v-if="priceTable">
      <table class="table table-striped  table-sm table-bordered">
        <thead>
          <tr>
            <td>Service</td>
            <td>Price/Pax</td>
            <td>Cost/Pax</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) of priceTable.services" :key="'S-' + index">
            <td class="text-left"> {{toDateTime(service.Fecha)}}: {{service.Titulo}}</td>
            <td class="text-right">{{service.PrecioPax}}</td>
            <td class="text-right">{{service.CostoPax}}</td>
          </tr>
          <tr class="bold">
            <td class="text-left">Total Services</td>
            <td class="text-right">{{ totalPriceServices.toFixed(2) }}</td>
            <td class="text-right">{{ totalCostServices.toFixed(2) }}</td>
          </tr>
          <tr class="bold">
            <td class="text-left" colspan="3">Hotels {{ this.roomType }}</td>
          </tr>
          <tr v-for="(hotel, index) of priceTable.hotels" :key="'H-' + index">
            <td class="text-left">{{toDateTime(hotel.CheckIn)}} a {{ toDateTime(hotel.CheckOut) }}: {{hotel.NombreHotel}} {{hotel.NombreLocacion}}</td>
            <td class="text-right">{{hotel['PrecioPax' + this.roomType]}}</td>
            <td class="text-right">{{hotel['CostoPax' + this.roomType]}}</td>
          </tr>
          <tr class="bold">
            <td class="text-left">Total Services</td>
            <td class="text-right text-strong">{{ totalPriceHotels.toFixed(2) }}</td>
            <td class="text-right text-strong   ">{{ totalCostHotels.toFixed(2) }}</td>
          </tr>
          <tr class="bold">
            <td class="text-left">Total</td>
            <td class="text-right text-strong">{{ (totalPriceHotels + totalPriceServices).toFixed(2) }}</td>
            <td class="text-right text-strong   ">{{ (totalCostHotels + totalCostServices).toFixed(2) }}</td>
          </tr>
        </tbody>
        <thead>
        </thead>
      </table>

    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
var moment = require('moment');
const api = window.api;
export default {
  name: 'SayHome',
  data () {
    return {
      bookingRef: 'WEPL100020',
      agent: 'TEST',
      processing: false,
      url: null,
      bookingId: null,
      priceTable: null,
      roomType: null,
      totalPriceServices: null,
      totalCostServices: null,
      totalPriceHotels: null,
      totalCostHotels: null,
      paxQuant: null
    }
  },
  computed: {
    booking() {
      return this.$store.state.booking;
    },
  },
  props: {
  },
  methods: {
    async find () {
      this.processing = true;
      let res = await api.get('/api/tp/booking_agent/', {Agent: this.agent, bookingRef: this.bookingRef, code: 'Z005'}, 
        (err) => { console.log(err), this.processing = false});
      let b = await api.post('/api/tp/request_booing_ref/', JSON.stringify({Agent: this.agent, bookingRef: this.bookingRef}), 
        null, (err) => { console.log(err), this.processing = false});
      this.processing = false;
      if (res && b) {
        this.$store.commit('setBooking', [null, null, res])
        this.bookingId = b.json.Reply.GetBookingReply.BookingId;
        this.url = `https://yourtrip.sayhueque.com/trip?Agent=${this.agent}&bookingRef=${this.bookingRef}&BookingId=${this.bookingId}`
      }
      return res;
    },
    showRows (roomType) {
      this.roomType = roomType;
      let services = _.filter(this.booking.Itinerario.LineaDetalle, (r) => {
        if (r.CodigoTipoDeServicio == 'IN') return false;
        if (r.CodigoTipoDeServicio == 'AC') return false;
        if (r.CodigoTipoDeServicio == 'XF') return false;
        if (r.CodigoTipoDeServicio == 'FT') return false;
        if (!r.PrecioPax && !r.CostoPax) return false;
        return true;
      })
      let hotels = _.filter(this.booking.Itinerario.LineaDetalle, (r) => {
        if (r.CodigoTipoDeServicio == 'AC') return true;
        return false;
      })
      this.totalPriceServices =  _.sumBy(services, (r) => parseFloat(r.PrecioPax));
      this.totalCostServices =  _.sumBy(services, (r) => parseFloat(r.CostoPax));
      this.totalPriceHotels =  _.sumBy(hotels, (r) => parseFloat(r['PrecioPax' + roomType]));
      this.totalCostHotels =  _.sumBy(hotels, (r) => parseFloat(r['CostoPax' + roomType]));
      this.priceTable = {services, hotels}
    },
    toDateTime (d) {
      return moment(d).locale(this.$root.langCode).format('DD MMM');
    }
  },
}
</script>

<style scoped>
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.bold {
  font-weight: bold !important;
}

</style>
