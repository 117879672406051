<template>
  <div>
    <div :id="id" :class="{'map-big': !small, 'map-small': small, 'corner-botton': small && hasPeru, 'corner-top': small && !hasPeru}"></div>
  </div>
</template>

<script>
import * as _ from 'lodash';
const google = window.google;
import { Loader } from "@googlemaps/js-api-loader"
export default {
  name: "GooglMap",
  props: {
    small: Boolean,
    id: String,
    bookingCities: Array
  },
  data() {
    return {
      ready: false,
      map: null,
      currentPlace: null,
      windowOpen: false,
      infoWindow: null,
      markers: [],
      cityImg: null,
      mapStyle: 'width:100%;  height: 590px;',
      citiesMark: [],
      options: {
        zoom: 4,
        mapId: '458390ce0801e61e',
        libraries: ['marker', 'geometry'],
        center: this.small? {lat: -27.119444, lng: -108.9} :{lat: -41, lng: -63},        
        disableDefaultUI: true,
        scaleControl: false,
        draggable: false,
        navigationControl: false,
        scrollwheel: false,
        mapTypeControl: false,
      },
      strokeColor: '#00BCD4',
      zoomMax: 21,
      lat1: null,
      lat2: null,
      mapDim: null,
      bounds: null
    };
  },
  mounted () {
    const loader = new Loader({
      apiKey: "AIzaSyDsCtoz0d8ZNiJKddqeu8iq2AKd_6DKHWg",
      version: "weekly",
      libraries: ['marker', 'geometry'],
      mapIds: ['a561f8e936f6948b']
    }); 
    loader.load().then(async () => {
      var pinURL;
      await Promise.resolve(import(`@/assets/img/ruta.svg`)).then((value) => {
        pinURL = value.default;
      }).catch(error => {
          console.error('Error al cargar la imagen:', error);
      });


      const google = window.google; 
      const map = new google.maps.Map(document.getElementById(this.id), this.options);
      this.getZoom();
      this.map = map;
      if (google.maps.marker && google.maps.marker.AdvancedMarkerElement) {
        this.$nextTick(() => {
            //setTimeout( () => {
              let markers = this.getMarkers();
              let markersList = [];

              markers.forEach((marker) => {
                const enlargeFactor = 1.5;

                const markerContainer = document.createElement('div');
                markerContainer.style.position = 'absolute';
                markerContainer.style.transform = 'translate(-50%, -100%)'; 
                markerContainer.style.display = 'flex';
                markerContainer.style.flexDirection = 'column';
                markerContainer.style.alignItems = 'center';
                markerContainer.style.marginLeft = 3 * enlargeFactor + 'px';
                markerContainer.style.marginTop = enlargeFactor + 'px';

                const iconImage = document.createElement("img");
                iconImage.src = pinURL;
                iconImage.style.width = 'auto';
                iconImage.style.height = 10 * enlargeFactor + 'px';
                
                const label = document.createElement('div');
                label.innerText = marker.label; 
                label.style.padding = '2px 5px';
                label.style.position = 'absolute';
                label.style.left = '50%';
                label.style.transform = 'translateY(-1px)';
                label.style.whiteSpace = 'nowrap';
                label.style.fontFamily = 'Arial, sans-serif'; 
                label.style.fontSize = '12px'; 
                label.style.color = 'black';
                label.style.fontWeight = 'bold'; 
                label.style.textAlign = 'center'; // Alineación del texto

                markerContainer.appendChild(label);
                markerContainer.appendChild(iconImage);                

                const m = new google.maps.marker.AdvancedMarkerElement({
                  position: marker.position,
                  map: map,
                  content: markerContainer,
                });
                markersList.push({ marker: m, label })
              });
              google.maps.event.addListenerOnce(map, 'idle', () => {
                  for (let i = 0; i < markersList.length; i++) {
                    for (let j = i + 1; j < markersList.length; j++) {
                      const marker1 = markersList[i];
                      const marker2 = markersList[j];
                      const pos1 = new google.maps.LatLng(marker1.marker.position);
                      const pos2 = new google.maps.LatLng(marker2.marker.position);

                      const distance = google.maps.geometry.spherical.computeDistanceBetween(pos1, pos2);

                      if (distance < 50) { // Ajusta este valor según sea necesario
                        marker1.label.style.bottom = '120%';
                        marker1.label.style.transform = 'translateY(-5px)';
                        marker2.label.style.bottom = '100%';
                        marker2.label.style.transform = 'translateY(5px)';
                      }
                    }
                  }
              });
              map.zoom = this.options.zoom;
              if (!this.small) this.map.setCenter(this.center);
              if (this.small) {
                const mainDiv = document.getElementById(this.id);
                let interval = setInterval(() => {
                  if (mainDiv && mainDiv.firstChild && mainDiv.firstChild.lastElementChild) {
                    const childNodes = mainDiv.firstChild.lastElementChild.childNodes;
                    if (childNodes && childNodes.length > 10) {
                      childNodes[childNodes.length - 1].style.display = 'none';
                      childNodes[childNodes.length - 2].style.display = 'none';
                      childNodes[childNodes.length - 3].style.display = 'none';
                      clearInterval(interval)
                    }
                  }
                }, 200);
              }
            //}, 500)
        })
      }
    }).catch(error => {
      console.error("Error loading Google Maps: ", error);
    });
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    hasPeru () {
      for (let city of this.bookingCitiesObject) {
        if (city.Latitude > -15 && city.Latitude < -72) return true;
      }
      return false;

    },
    bookingCitiesObject () {
      let res = [];
      for (let city of this.bookingCities) {
        if (city.Name == 'Isla de Pascua﻿' && !this.small) continue;
        if (city.Name != 'Isla de Pascua﻿' && this.small) continue;
        res.push(city)
      }
      return res;
    },
    center () {
        let lat = -41;
        let lng = -63;
        if (!this.bookingCitiesObject.length) return {lat: -41, lng: -63}
        let lats = [];
        let longs = [];
        for (let city of this.bookingCitiesObject) {
            if (city && city.Latitude && city.Longitude) {
                lats.push(city.Latitude);
                longs.push(city.Longitude);
            }
        }
        let lat1 = Math.max.apply(null, lats);
        let lat2 = Math.min.apply(null, lats);
        lat = (lat1 + lat2) / 2;
        let lng1 = Math.max.apply(null, longs);
        let lng2 = Math.min.apply(null, longs);
        lng = (lng1 + lng2) / 2;
        return { lat, lng };
    },
  },
  methods: {
    tr (t) {
        //return tr(t, this.languageCode);
        return t;
    },
    updateCurveMarker (myPos1, myPos2) {

        if (!this.map) return;
        let pos1 = {lat: myPos1.lat, lng: myPos1.lng};
        let pos2 = {lat: myPos2.lat, lng: myPos2.lng};
        var curvature = 0.3;
        var curveMarker;

        var projection = this.map.getProjection();
        let p1 = new google.maps.LatLng(pos1);
        let p2 = new google.maps.LatLng(pos2);
        if (!projection) return;
        p1 = projection.fromLatLngToPoint(p1);
        p2 = projection.fromLatLngToPoint(p2);

        var e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y), // endpoint (p2 relative to p1)
            m = new google.maps.Point(e.x / 2, e.y / 2), // midpoint
            o = new google.maps.Point(e.y, -e.x), // orthogonal
            c = new google.maps.Point( // curve control point
                m.x + curvature * o.x,
                m.y + curvature * o.y);
        var pathDef = 'M 0,0 ' +
            'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;
        //var zoom = this.map.getZoom();
        let zoom = this.options.zoom;
        var scale = 1 / (Math.pow(2, -zoom));

        var symbol = {
            path: pathDef,
            scale: scale,
            strokeWeight: 1.5,
            strokeColor: this.strokeColor,
        };

        curveMarker = {
            position: pos1,
            icon: symbol,
            zIndex: -10, // behind the other markers
        };
        return curveMarker;
    },
    latRad (lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    },
    zoomPX (mapPx, worldPx, fraction) {
        return Math.log(mapPx / worldPx / fraction) / Math.LN2;
    },
    getZoom () {
        let lats = [];
        let longs = [];
        for (let city of this.bookingCitiesObject) {
            if (city && city.Latitude && city.Longitude) {
                lats.push(city.Latitude);
                longs.push(city.Longitude);
            }
        }
        let lat1 = Math.max.apply(null, lats);
        let lat2 = Math.min.apply(null, lats);
        let lng1 = Math.max.apply(null, longs);
        let lng2 = Math.min.apply(null, longs);
        let latDiff = (lat1 - lat2);
        let lngDiff = (lng1 - lng2);
        this.lat1 = lat1;
        this.lat2 = lat2;
        let rel = latDiff / lngDiff;
        if (rel > 0.2 && rel < 5) {
            this.mapStyle = 'width:100%;  height: 690px;'
        }

        var WORLD_DIM = { height: 360, width: 360 };

        let $mapDiv = document.getElementById('mapDiv');

        var mapDim = {
            height: $mapDiv.clientHeight,
            width: $mapDiv.clientWidth
        }
        this.mapDim = mapDim;

        var latFraction = (this.latRad(lat1) - this.latRad(lat2)) / Math.PI;

        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom;
        var lngZoom;
        latZoom = this.zoomPX(mapDim.height, WORLD_DIM.height, latFraction);
        lngZoom = this.zoomPX(mapDim.width, WORLD_DIM.width, lngFraction);
        if (!latFraction && !lngFraction) {
            latZoom = 7;
            lngZoom = 7;
        }
        this.options.zoom =  Math.min(latZoom, lngZoom, this.zoomMax);
    },
    getMarkers () {
        let markers = [];
        for (let city of this.bookingCitiesObject) {
            if (!city) continue;
            if (_.find(markers, (m) => m.label == city.Name)) continue;
            if (city && city.Latitude && city.Longitude) {
                let pos = {lat: city.Latitude, lng: city.Longitude}
                let marker = {
                    position: pos,
                    label: city.Name,
                    zIndex: 1,
                };
                markers.push(marker);
            }
        }
        return markers;
    },
  },
};
</script>

<style scoped>

.map-big {
  min-width: 200px; 
  min-height: 700px;
}
.map-small {
  width: 100%;
  height: 100%;
}
.corner-botton {
  border-right: 1px solid black;
  border-top: 1px solid black;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0 5% 0 0;
  border-radius: 0 5% 0 0; 
}
.corner-top {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0 0 5% 0;
  border-radius: 0 0 5% 0; 
}

</style>