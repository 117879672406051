//import '@/js/index';
import 'flowbite/dist/flowbite.min.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import AsyncComputed from 'vue-async-computed';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import '@/assets/tailwind.css';

//import '@/assets/css/flowbite.min.css';

var swiper = new Swiper(".swiper-slider", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
});
swiper;

const app = createApp(App);

app.config.productionTip = false;
app.use(router);
app.use(store); 
app.use(AsyncComputed)

app.mount('#app');
