<template>
     <section>
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-0 my-6">

          <div class="w-full md:w-6/12 pt-10 md:pr-20 overview">
              <h2 class="font-secondary text-2xl md:text-3xl mb-8">
                {{tr('Overview')}}
            </h2>
            <div class="flex text-dark font-normal text-md mb-2" v-for="(day, index) of dayList" :key="index">
                  <span class="uppercase font-bold inline-block mr-2 w-2/12">{{day.date}}</span>
                  <div class="w-10/12 flex flex-col" v-if="titles && titles[index]">
                    <span class="font-light" v-for="(title, ti) of titles[index]" :key="'ti-' + ti">
                      {{title}}.
                    </span>
                  </div>
            </div>
          </div>
          <div class="w-full md:w-6/12" v-if="services && bookingCities && bookingCities.length">
            <div class="overflow-hidden rounded-xl maps-container" id="mapDiv">
              <GoogleMap id="mainMap" ref="mainMap" :bookingCities="bookingCities"></GoogleMap>
              <GoogleMap :class="{'overlay-top': !hasPeru, 'overlay-bottom': hasPeru}"  v-if="hasPascua"
                :small="true" id="smallMap" :bookingCities="bookingCities"></GoogleMap>
            </div>
          </div>
      </div>
    </section>
</template>
<script>
import * as _ from 'lodash';
var moment = require('moment');
import { HTMLToJSON } from 'html-to-json-parser'; // ES6
import GoogleMap from '@/components/itinerary/GoogleMap.vue';
export default {
  name: 'TripOverview',
  props: {
  },
  data () {
    return {
      maps: false
    }
  },
  components: {
    GoogleMap
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.mainMap) this.maps = true;
    });
  },
  asyncComputed: {
    async titles () {
      let titles = {};
      if (this.dayList && this.dayList.length) {
        for (let j=0; j<this.dayList.length; j++) {
          titles[j] = {};
          let day = this.dayList[j];
          for (let i=0; i<day.services.length; i++) {
            if (!day.services[i].title) continue;
            let res = await HTMLToJSON(day.services[i].title.replace(/<br>/g, '<br/>'), false);
            if (res.content && res.content[0]) {
              let value = _.find(res.content[0].content, (r) => r.type != 'br');
              if (value) {
                titles[j][i] = value;
              }     
            }
          }
        }
      }
      return titles;
    },
    async bookingCities () {
      let res = [];
      if (this.services && this.services.length) {
        let rows = _.filter(this.services, (r) => r.Coordenadas);
        rows = _.map(rows, (r) => r.Coordenadas);
        for (let row of rows) {
          let rowsJson = await HTMLToJSON(row.replace(/<br>/g, '<br/>'), false);
          for (let cities of rowsJson.content){
            let value = {}
            for (let p of cities.content)   {
              if (p.type) continue;
              let coords = p.split('|');
              if (coords.length > 3) {
                value.Longitude = parseFloat(coords[2]);
                value.Latitude = parseFloat(coords[1]);
              }
              if (coords.length == 1) {
                value.Name = p;
              } 
            }
            if (value.Name && value.Latitude && value.Longitude) {
              res.push(value);
            }
          }
        }
      }
      return res;
    },

  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    languageNotes () {
      return this.$store.state.languageNotes;
    },
    services () {
      let res = this.$store.state.services || [];
      const languageNotes = this.languageNotes || [];
      if (languageNotes) {
        res = res.map(service => {
          const matchingNote = languageNotes.find(note => note.OPT_ID === service.OPT_ID && note.NTS_CATEGORY === 'ODE');
          if (matchingNote) {
            service.Titulo = matchingNote.MESSAGE_TEXT;
          }
          const matchingNote2 = languageNotes.find(note => note.OPT_ID === service.OPT_ID && note.NTS_CATEGORY === 'OIE');
          if (matchingNote2) {
            service.Descripcion = matchingNote2.MESSAGE_TEXT;
          }
          return service;
        });
      }
      return res;
    },
    hasPascua () {
      return _.find(this.bookingCities, (r) => r.Longitude < -100);
    },
    hasPeru () {
      if (this.maps) {
        if (this.$refs.mainMap.hasPeru) return true;
      }
      return false;
    },     
    dayList () {
      let res = [];
      if (this.services) {
        let days = _.map(this.services, (e) => {
          let d = moment(e.date, 'YYYY-MM-DD');
          return {
            date: d,
            dateString: d.format('YYYY-MM-DD'),
            title: e.Titulo || '',
            code: e.OPT_ID,
            description: e.Descripcion || '',
            location: e.LocationCode,
            locationName: e.LocationName,
          }
        });
        let unique = _.uniqBy(days, 'dateString');
        unique.sort((a, b) => {
          if (a.dateString > b.dateString) return 1;
          if (a.dateString < b.dateString) return -1;
          return 0
        });
        let firstDate = unique[0]? unique[0].date: null;
        res = _.map(unique, (r) => {
            return {
              date: r.date.locale(this.$root.langCode).format('DD MMM'),
              dateLong: `${r.date.locale(this.$root.langCode).format('dddd DD MMM YYYY')}`.toUpperCase(),
              dateString: r.dateString,
              dayNr: r.date.diff(firstDate, 'day') + 1,
              services: _.map(_.filter(days, (d) => {
                  return d.dateString == r.dateString;
                }), (t) => {
                return {
                  title: t.title,
                  code: t.code,
                  description: t.description,
                  location: t.location,
                  locationName: t.locationName,
                }
              })
            }
        })
      }
      return res;
    }
  },
  methods: {
    tr(key) {
      return this.$root.tr(key);
    }
  }
}
</script>

<style scoped>

.overview {
  padding-top: 0px;
}

.maps-container {
  position: relative;
  
}
.overlay-bottom {
  position: absolute;
  bottom: 0%; 
  left: 0; 
  width: 30%;
  height: 4rem;
}
.overlay-top {
  position: absolute;
  top: 0%; 
  left: 0; 
  width: 25%;
  height: 4rem;
}
</style>