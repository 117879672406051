<template>
  <div id="booking-form">
    <main class="">
    <section class="bg-primary h-50vh md:h-70vh w-full pt-4 relative pb-8 main-hero  bg-cover bg-center bg-no-repeat pt-6 md:pt-12 pb-8 md:pb-20" :style="imgBack">


       <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-col flex-wrap justify-between h-full ">
          <div class=" w-full">
            <div class="site-logo">
              <a href="#"><img src="@/assets/img/logo.svg"></a>
            </div>
          </div>
          <div class="w-full ">
            <h1 class="md:w-6/12 text-white font-secondary leading-tight text-4xl md:text-5-6xl font-normal md:pr-16 mb-3 text-left">Booking Form </h1>
            <p class="text-white font-light text-lg md:text-1/3xl" v-html="paxName"></p>
          </div>

          
          
       </div>
    </section>

    <section class="py-8 md:py-12" v-if="ready">
      <form class="w-full" action="">
        <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-wrap w-full py-8 border-b-2 border-[#4C535966]">
          <div class="w-full">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            Contact
            </h2>
          </div>
          <div class="w-full grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 items-end">
            <div class="mb-5">
              <label  class="block mb-2 text-sm font-medium text-gray uppercase">Reservation Name<span class="ml-2 text-primary">*</span></label>
              <input type="text" :class="{'error': contactErrors.name}" v-model="contact.name" 
                class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
            </div>

            <div class="mb-5">
              <label  class="block mb-2 text-sm font-medium text-gray uppercase">Address<span class="ml-2 text-primary">*</span></label>
              <input type="text" :class="{'error': contactErrors.address}" v-model="contact.address" 
                class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3 "  />
            </div>

            <div class="mb-5">
              <label class="block mb-2 text-sm font-medium text-gray uppercase">ZIP CODE<span class="ml-2 text-primary">*</span></label>
              <input type="text"  :class="{'error': contactErrors.zipcode}" v-model="contact.zipcode" 
                class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3 " />
            </div>

             <div class="mb-5">
              <label class="block mb-2 text-sm font-medium text-gray uppercase">Nationality<span class="ml-2 text-primary">*</span></label>
              <select id="countries"  :class="{'error': contactErrors.nationality}"  v-model="contact.nationality"
                class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
                <option v-for="(c, i) of countriesJSON" :key="i" :selected="c.name == contact.nationality">{{ c.name }}</option>
              </select>
            </div>

            <div class="mb-5">
              <label  class="block mb-2 text-sm font-medium text-gray uppercase">Cellphone</label>
              <input type="number" v-model="contact.mobile" 
                class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " />
            </div>

            <div class="mb-5">
              <label  class="block mb-2 text-sm font-medium text-gray uppercase">Email<span class="ml-2 text-primary">*</span></label>
              <input type="email" :class="{'error': contactErrors.email}" v-model="contact.email" 
                class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3 "  />
            </div>

            <div class="mb-5 sm:col-span-2">
              <p class="text-primary text-sm">You will receive a digital version of your travel documents (travel itinerary, vouchers, etc.), delivered directly to your email inbox.</p>
              
            </div>
          </div>


        </div>

        <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-wrap w-full py-8 border-b-2 border-[#4C535966] mt-2 md:mt-4">
          <div class="w-full">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            Travelers
            </h2>
          </div>
          <div v-for="(pax, j) of passengers" :key="'pax-' + j" class="mb-8">
            <BookingFormPax :passenger="passengers[j]" @update="updatePax($event, j)" :errors="paxErrors[j]"></BookingFormPax>
            <div class="w-full text-right trash mt-3" @click="passengers.splice(j, 1)" v-if="j>0">
              X
            </div>
          </div>
          <div class="mb-5 sm:col-span-4 mt-5">
            <button class="py-2 px-4 border cursor-pointer border-primary rounded-md text-sm text-primary hover:bg-primary hover:text-white" @click.prevent="addPax">+ Add Traveler</button>
          </div>

        </div>

        <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-wrap w-full py-8 border-b-2 border-[#4C535966] mt-2 md:mt-4">
          <div class="w-full">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            International Fligths <span class="font-light font-primary text-1/2xl md:text-2xl">(Booked on your own)</span>
            </h2>
          </div>
          <div v-for="(f, j) of internationalFlights" :key="'flightI-' + j" class="w-full mb-8">
            <BookingFormFlight :flight="internationalFlights[j]" @update="updateFlightInternational($event, j)"></BookingFormFlight>
            <div class="w-full text-right trash mt-3" @click="internationalFlights.splice(j, 1)" v-if="j>0">
              X
            </div>
          </div>

          <div class="mb-5 sm:col-span-2 mt-5">
            <button class="py-2 px-4 border cursor-pointer border-primary rounded-md text-sm text-primary hover:bg-primary hover:text-white" @click.prevent="addFlightInternational">+ Add Flight</button>
          </div>
        </div>

        <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-wrap w-full py-8 border-b-2 border-[#4C535966] mt-2 md:mt-4">
          <div class="w-full">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            Domestic Fligths <span class="font-light font-primary text-1/2xl md:text-2xl">(Booked on your own)</span>
            </h2>
          </div>
          <div v-for="(f, j) of domesticFlights" :key="'flightD-' + j" class="w-full mb-8">
            <BookingFormFlight :flight="domesticFlights[j]" @update="updateFlightDomestict($event, j)"></BookingFormFlight>
            <div class="w-full text-right trash mt-3" @click="domesticFlights.splice(j, 1)" v-if="j>0">
              X
            </div>
          </div>

          <div class="mb-5 sm:col-span-2 mt-5">
            <button class="py-2 px-4 border cursor-pointer border-primary rounded-md text-sm text-primary hover:bg-primary hover:text-white" @click.prevent="addFlightDomestict">+ Add Flight</button>
          </div>
        </div>

        <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-wrap w-full py-8 border-b-2 border-[#4C535966]">
          <div class="w-full">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            Rooms Information
            </h2>
          </div>
          <div v-for="(f, j) of rooms" :key="'room-' + j" class="w-full">
            <BookingFormRoom :room="rooms[j]" @update="updateRoom($event, j)"></BookingFormRoom>
          </div>

          <div class="mb-5 sm:col-span-3 mt-5">
            <button class="py-2 px-4 border cursor-pointer border-primary rounded-md text-sm text-primary hover:bg-primary hover:text-white" @click.prevent="addRoom">+ Add Room</button>
          </div>
        </div>

        <div class="max-w-screen-2xl mx-auto px-4 flex relative flex-wrap w-full py-8 mt-2 md:mt-4">
          <div class="w-full">

           <div class="flex flex-wrap w-full md:w-1/3 mx-auto items-center justify-center font-normal text-md">
              
              <label for="link-checkbox" class="ms-2 ">
                <input v-model="accept" id="link-checkbox" type="checkbox" value="" class="w-4 h-4 text-black mr-2">
                  I have read and accept the 
                  <a href="https://sayhueque.com/terms-conditions/" target="_blank" class="underline font-bold uppercase">terms and conditions</a>.
            </label>

              <button :class="{'disabled': !accept}" @click.prevent="send" :disabled="!accept"
                class="bg-primary py-2 px-4 w-full hover:bg-dark transition-all rounded-md mt-8 text-white font-semibold text-lg">Send</button>
            </div>
        
          </div>
        </div>


      </form>
    </section>



  </main>

    <Footer></Footer>
    <Transition>
        <BlockUI v-if="loading"></BlockUI>
    </Transition>
  </div>
</template>

<script>
import BlockUI from '@/components/tools/BlockUI.vue';
import Footer from '@/components/tools/Footer.vue';
import BookingFormPax from '@/components/bookingform/BookingFormPax.vue';
import BookingFormFlight from '@/components/bookingform/BookingFormFlight.vue';
import BookingFormRoom from '@/components/bookingform/BookingFormRoom.vue';
import '@/assets/css/main.scss';
let countriesJSON = require(`@/assets/world.json`);
const api = window.api;
export default {
  name: 'BookingFrom',
  data() {
    return {
      loading: false,
      accept: false,
      imgBack: null,
      ready: true,
      countriesJSON: countriesJSON,
      contact: {
        nationality: 'Argentina',
        zipcode: 1,
        email: 1,
        address: 1,
        name: 1,
        mobile: null
      },
      passengers: [],
      internationalFlights: [],
      domesticFlights: [],
      rooms: [],
      trySend: false,
      pax: {
        name: 1,
        lastName: 1,
        DOB: '2020-01-01',
        gender: null,
        nationality: 'Argentina',
        passport: 1,
        address: 1,
        email: 1,
        mobile: 1,
        tripReason: null,
        allergies: null,
        medicalConditions: null,
        medication: null,
        foodRestriction: null,
        hobbies: null,
        files: [],
      },
      flight: {
        airline: null,
        number: null,
        reservationCode: null,
        departureDate: null,
        arrivalDate: null,
        departureCity: null,
        arrivalCity: null,
        departureTime: null,
        arrivalTime: null
      },
      room: {
        type: null,
        names: null,
      },
      paxRequired: ['name', 'lastName', 'DOB', 'passport', 'nationality', 'email', 'mobile'],
      contactRequired: ['name', 'address', 'email', 'nationality', 'zipcode'],
      paxErrors: {},
      contactErrors: {
        nationality: null,
        zipcode: null,
        email: null,
        address: null,
        name: null,
      }
    }
  },
  props: {
  },
  computed: {
    coverImg () {
      return this.$store.state.coverImg;
    },
    tripName () {
      return this.$store.state.tripName;
    },
    paxName () {
      return `For ${this.$store.state.paxName}`;
    },
  },
  async mounted() {
    this.ready = false;
    await Promise.resolve(import(`@/assets/img/fondoform.png`)).then((value) => {
      this.imgBack = {
        backgroundImage: "url(" + value.default + ")",
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
      };
    });
    if (!this.$route.query.Code) return;

    //this.passengers.push(Object.assign({}, this.pax));
    this.addPax();
    this.domesticFlights.push(Object.assign({}, this.flight));
    this.internationalFlights.push(Object.assign({}, this.flight));
    this.rooms.push(Object.assign({}, this.room));
    this.loading = true;
    api.get('/api/tpsql/trip_cover', this.$route.query, null, (result) => {
      this.$store.commit('setNames', result);
    });
    api.get('/api/tpsql/trip_seller', this.$route.query, null, (r) => {
      if (r && r[0]) {
        this.$store.commit('setSeller', r[0]);
        this.ready = true;
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
    this.$store.commit('setQuery', this.$route.query);
    //setTimeout(() => {
        //this.loading = false;
    //}, 1000);
  },
  methods: {
    addPax () {
      this.paxErrors[this.passengers.length] = {};
      this.passengers.push(Object.assign({}, this.pax))
    },
    addFlightInternational () {
      this.internationalFlights.push(Object.assign({}, this.flight))
    },
    addFlightDomestict () {
      this.domesticFlights.push(Object.assign({}, this.flight))
    },
    addRoom () {
      this.rooms.push(Object.assign({}, this.room))
    },
    updatePax (event, j) {
      this.passengers[j] = Object.assign({}, event);
      this.check();
    },
    updateFlightDomestict (event, j) {
      this.domesticFlights[j] = Object.assign({}, event);
    },
    updateFlightInternational (event, j) {
      this.internationalFlights[j] = Object.assign({}, event);
    },
    updateRoom (event, j) {
      this.rooms[j] = Object.assign({}, event);
    },
    async send () {
      this.trySend = true;
      if (!this.check()) {
        alert('Error');
        return;
      }
      const formData = new FormData();

      for (let key in this.contact) {
          formData.append(`contact[${key}]`, this.contact[key]);
      }

      this.passengers.forEach((passenger, pIndex) => {
        for (let key in this.pax) {
          if (key == 'files') continue;
          formData.append(`passengers[${pIndex}][${key}]`, passenger[key]);
        }
        //formData.append(`passengers[${pIndex}][name]`, passenger.name);
        
        Array.from(passenger.files).forEach((photo, phIndex) => {
          if (photo) {
            formData.append(`passengers[${pIndex}][passportPhotos][${phIndex}]`, photo);
          }
        });
      });

      this.internationalFlights.forEach((flight, fIndex) => {
        for (let key in this.flight) {
          formData.append(`internationalFlights[${fIndex}][${key}]`, flight[key]);
        }

      });
      this.domesticFlights.forEach((flight, fIndex) => {
        for (let key in this.flight) {
          formData.append(`domesticFlights[${fIndex}][${key}]`, flight[key]);
        }
      });

      let headers = {
      }
      api.post('/api/tpsql/bookingform/' + this.$route.query.Code, formData, headers); 

    },
    check () {
      if (!this.trySend) return true;
      let res = true;
      for (let field of this.contactRequired) {
        if (!this.contact[field]) {
          res = false;
          this.contactErrors[field] = true;
        } else {
          this.contactErrors[field] = false;
        }
      }
      for (let i in this.passengers) {
        if (!this.paxErrors[i]) this.paxErrors[i] = {};
        for (let field of this.paxRequired) {
          if (!this.passengers[i][field]) {
            res = false;
            this.paxErrors[i][field] = true;
          } else {
            this.paxErrors[i][field] = false;
          }
        }
      }
      this.contactErrors = Object.assign({}, this.contactErrors);
      this.paxErrors = Object.assign({}, this.paxErrors);
      return res;
    }
  },
  components: {
    BlockUI,
    Footer,
    BookingFormPax,
    BookingFormFlight,
    BookingFormRoom
  }
}
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
}
.disabled:hover {
  background-color: grey;
}
.error {
  border: 2px solid red;
}

.trash {
  cursor: pointer;
}

</style>
