<template>
    <div class="border-l-2 border-primary circle active relative pl-6 flex flex-wrap w-full items-start pb-12 md:pb-20">
      <div class="w-full md:w-6/12 md:pr-20 "> 
          <p class="text-primary font-secondary uppercase text-sm mb-3">{{tr('DAY')}} {{day.dayNr}} | {{ day.dateLong }}</p>
          <h3 class="font-secondary text-2xl text-dark">{{day.services[0].locationName}}</h3>
          <p class="mt-3 destiny-subtitle font-bold relative ml-5">
            {{ title }}
          </p>
          <div class="font-light leading-tight">
            <p class="mb-3" :class="{'max-h-0 day-text': hide }" v-html="day.services[0].description" :id="'day-text' + day.dayNr">
            </p>

          </div>
          <a class="text-primary font-light inline-block mb-3 hover:underline cursor-pointer mt-3" @click="hide = !hide" v-if="showReadMore">
            <span v-if="!hide">{{tr('Hide')}}</span> 
            <span v-else>{{tr('Read more')}}</span> 
          </a>
        </div>
      <div class="w-full md:w-6/12" :id="'images-' + day.dayNr"> 
        <DaySwiper
          imgClass="img-servcie rounded-xl"
          :images="images"
        ></DaySwiper>
      </div>
    </div>
</template>

<script>
const s3 = process.env.VUE_APP_S3;
import DaySwiper from '@/components/itinerary/DaySwiper.vue';
import * as _ from 'lodash';
import { HTMLToJSON } from 'html-to-json-parser'; // ES6
export default {
  name: 'TripDay',
  props: {
    day: Object
  },
  data () {
    return {
      booking: null,
      readMore: false,
      showReadMore: true,
      images: [],
      hide: true,
      title: null
    }
  },
  async mounted () {
    this.images = [];
    for (let s of this.day.services) {
      if (!s.code) continue;
      let img = `${s3}/OPT_ID.${s.code}.0.png`;
      this.checkImage(img, (exist) => {
        if (exist) this.images.push({url: img});
      });
    }
    if (this.day.services[0].title) {
      let res = await HTMLToJSON(this.day.services[0].title.replace(/<br>/g, '<br/>'), false);
      this.title = _.filter(res.content[0].content, (r) => r.type != 'br')[0];     
    }
    setTimeout(() => {
      let divs = document.getElementById('images-' + this.day.dayNr)
      if (divs) {
        let divs2 = document.getElementById('day-text' + this.day.dayNr)
        if (divs2) {
          let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
          let remp = parseFloat(getComputedStyle(divs2).fontSize);
          let calc = (vw - remp) * 0.226 - 80;

          if (divs2.clientHeight <  calc) {
            if (this.day.dayNr == 3)
            this.showReadMore = false;
            //this.hide = false;
          }
        }
      }

    }, 3000)
  },
  methods: {
    async getImagePath (url) {
        await Promise.resolve(import(`@/assets/${url}`)).then((value) => {
            this.images.push({url: value.default})
        });
    },
    async checkImage (url, callback) {
      const img = new Image();
      img.onload = () => callback(true);
      img.onerror = () => callback(false);
      img.src = url;
    },
    tr(key) {
      return this.$root.tr(key);
    }
  },
  components: {
    DaySwiper,
  }
}
</script>

<style scoped>
.day-text {
  overflow: hidden;
  max-height: 200px;
  @media screen and (min-width: 798px) {
    max-height: calc(100vw * 0.226 - 80px) ;
  } 
}
</style>
