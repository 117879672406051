<template>
    <div class="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 items-end">
      <div class="mb-5">
        <label class="block mb-2 text-sm font-medium text-gray uppercase">Room Type</label>
        <select v-model="r.type" @change="update" id="roomTypes" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent outline-none block w-full p-3">
          <option :selected="r.type == 'SGL'" value="SGL">Single</option>
          <option :selected="r.type == 'DBL'" value="DBL">Double</option>
          <option :selected="r.type == 'TPL'" value="TPL">Triple</option>
          <option :selected="r.type == 'OTHERS'" value="OTHERS">Others</option>
        </select>
      </div>
      <div class="mb-5">
        <label  class="block mb-2 text-sm font-medium text-gray uppercase">Guest</label>
        <input v-model="r.names" @change="update" type="text" class="bg-light-gray border border-[#ADAEAE] text-gray text-sm rounded-lg focus:border-[#ADAEAE] focus:ring-transparent  outline-none block w-full p-3 " placeholder="Please, write the names of the people in the room." />
      </div>
    </div>
</template>

<script>
export default {
  name: 'BookingFromRoom',
  data() {
    return {
      r: {}
    }
  },
  mounted () {
    this.r = Object.assign({}, this.room);
  },
  props: {
    room: Object
  },
  methods: {
    update () {
      this.$emit('update', this.r)
    }
  }
}
</script>

