<template>
    <section class="bg-light-gray py-6 md:py-12">
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-12 my-6 justify-center">

          <div class="md:10/12 w-full mx-auto flex-wrap flex">
              <div class="w-full mb-12">
                  <h4 class="font-secondary text-2xl text-center">{{tr('Follow these easy steps to start your adventure!')}}</h4>
              </div>

              <div class="w-full md:w-1/3 mb-12 md:mb-0 text-center flex-wrap justify-center">

                <span class="flex items-center justify-center border-6 w-24 h-24 inline-block mx-auto rounded-full border-primary text-center text-primary font-black text-3xl">
                    1
                </span>
                <p class="font-normal text-lg text-dark md:w-6/12 pt-5 leading-tight mx-auto">
                  <a @click="openBookingForm">{{tr('Click here and complete the')}} <u class="uppercase font-bold">{{tr('Booking Form.')}}</u></a>
                </p>
              </div>

              <div class="w-full md:w-1/3 mb-12 md:mb-0 text-center flex-wrap justify-center">

                <span class="flex items-center justify-center border-6 w-24 h-24 inline-block mx-auto rounded-full border-primary text-center text-primary font-black text-3xl">
                    2
                </span>
                <p class="font-normal text-lg text-dark md:w-6/12 pt-5 leading-tight mx-auto">
                  {{tr('Secure your trip by paying a 30% deposit of the cost.')}}
                </p>
              </div>

              <div class="w-full md:w-1/3 md:mb-0 text-center flex-wrap justify-center">

                <span class="flex items-center justify-center border-6 w-24 h-24 inline-block mx-auto rounded-full border-primary text-center text-primary font-black text-3xl">
                    3
                </span>
                <p class="font-normal text-lg text-dark md:w-6/12 pt-5 leading-tight mx-auto">
                  {{tr('We will contact you with the final details.')}}
                </p>
              </div>

          </div>
      </div>
      
    </section>

</template>
<script>
export default {
  name: 'NextSteps',
  props: {
  },
  data () {
    return {
      booking: null
    }
  },
  methods: {
    openBookingForm () {
      let route = this.$router.resolve({ name: 'BookingForm', params: this.$route.query })
      route.href = route.href + '?' + new URLSearchParams(this.$route.query).toString();
      window.open(route.href, '_blank');
    },
    tr(key) {
      return this.$root.tr(key);
    } 
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>