<template>
    <div class="swiper-button-next" @click="swiper.slideNext()" :class="{'swiper-button-disabled': index == length -1}"></div>
    <div class="swiper-button-prev" @click="swiper.slidePrev()"  :class="{'swiper-button-disabled': index == 0}"></div>
</template>

<script>
    import { useSwiper } from 'swiper/vue';
      export default {
      name: 'SwiperSlide',
      props: {
        index: Number,
        length: Number,
      },
      setup() {
        const swiper = useSwiper();
  
        return {
          swiper,
        };
      },
    };
  </script>