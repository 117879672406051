<template>
  <section class="bg-primary h-50vh md:h-70vh w-full pt-4 relative pb-8 main-hero  bg-cover bg-center bg-no-repeat pt-6 md:pt-12 pb-8 md:pb-20" :style="imgBack">
    <div class="max-w-screen-2xl mx-auto px-4 flex relative z-10 flex-col flex-wrap justify-between h-full ">
      <div class=" w-full">
        <div class="site-logo">
          <a href="#"><img src="@/assets/trip/img/logo.svg"></a>
        </div>
      </div>
      <div class="w-full ">
        <h1 class="md:w-6/12 text-white font-secondary leading-tight text-4xl md:text-5-6xl font-normal md:pr-16 mb-3 text-left">{{category}} Hotels </h1>
        <p class="text-white font-light text-lg md:text-1/3xl">Selected hotels for {{ paxName }}</p>
      </div>
    </div>
  </section>

</template>

<script>
const s3 = process.env.VUE_APP_S3;
import * as _ from 'lodash';
export default {
  name: 'HotelsHeader',
  props: {
  },
  data () {
    return {
        bghotels: null,
        imgBack: null
    }
  },
  async mounted () {
    await this.getImagePath();
    this.imgBack  = {
        backgroundImage: "url(" + this.bghotels + ")",
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
    };
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    bookingCode () {
      return this.$store.state.query.bookingRef;
    },
    tripName () {
      if (this.booking) return this.booking.NombreDelViaje;
      return ''
    },
    category () {
      if (this.booking && this.booking.Itinerario && this.booking.Itinerario.LineaDetalle) {
        let rows = _.filter(this.booking.Itinerario.LineaDetalle, (r) => {
          if (r.CodigoTipoDeServicio == 'AC') return true;
          return false;
        })
        rows = _.uniqBy(rows, (r) => r.CodigoClase);
        if (rows.length == 1) {
          return rows[0].NombreClase;
        }
      }
      return '';
    },

    paxName () {
      /*if (this.booking && this.booking.Pasajeros.Pasajero && this.booking.Pasajeros.Pasajero.length > 0) {
        let pax = this.booking.Pasajeros.Pasajero[0]
        if (this.booking.Pasajeros.Pasajero.length > 1) {
          return `${pax.Nombre} ${pax.Apellido} & Co.`;
        }
        return `${pax.Nombre} ${pax.Apellido}`;
      }*/
      return ''
    },
    image () {
      let img = `${s3}/COVER.${this.bookingCode}.0.png`;
      return {
        backgroundImage: "url(" + img + ")",
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
      };
    }

  },
  asyncComputed: {
  },
  methods: {
    async getImagePath () {
        await Promise.resolve(import(`@/assets/img/bghoteles.png`)).then((value) => {
            this.bghotels = value.default;
        });
    }

  }

}
</script>
