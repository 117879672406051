<template>
    <section class="bg-primary h-90vh w-full pt-4 relative pb-8 main-hero  bg-cover bg-center bg-no-repeat pt-6 md:pt-12" :style="image">


        <div class="max-w-screen-2xl mx-auto px-4 flex relative z-10 flex-col flex-wrap justify-between h-full ">
        <div class=" w-full">
            <div class="site-logo">
            <a href="#"><img src="@/assets/trip/img/logo.svg"></a>
            </div>
        </div>
        <div class="w-full ">
            <h1 class="md:w-6/12 text-white font-secondary leading-tight text-4xl md:text-5-6xl font-normal md:pr-16 mb-3 text-left" v-html="tripName"></h1>
            <p class="text-white font-light text-lg md:text-1/3xl" v-html="paxName">
            </p>
        </div>

        <div class="w-full flex flex-wrap">
            <div class="w-full md:w-6/12 mb-4 md:mb-0">
                <p class="font-bold text-md text-white opacity-75 mb-1">{{tr('Awards, Press & Recommendations')}}</p>
                <div class="flex gap-8 opacity-90 items-center">
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/cnn.png">
                    </div>
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/lonely+planet+logo+ 1.png">
                    </div>
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/national geographic.png">
                    </div>
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/nytimes-logo-png-new-york-times-296 1.png">
                    </div>
                    
                </div>
            </div>
            <div class="w-full md:w-6/12 md:justify-end flex">
              <div>

                <p class="font-bold text-md text-white opacity-75 mb-1">{{tr('Proud Members of')}}</p>
                <div class="flex gap-8 opacity-90 items-center">
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/trade association.png">
                    </div>
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/TransformationalTravel.png">
                    </div>
                    <div class="w-auto">
                    <img class="grayscale invert" src="@/assets/trip/img/certifies-b-corporation 2.png">
                    </div>
                    
                </div>
              </div>
            </div>
        </div>
        
        </div>
        </section>

</template>

<script>
//const s3 = process.env.VUE_APP_S3;
export default {
  name: 'TripHeader',
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    booking () {
      return this.$store.state.booking;
    },
    coverImg () {
      return this.$store.state.coverImg;
    },
    bookingCode () {
      return this.$store.state.query.bookingRef;
    },
    tripName () {
      return this.$store.state.tripName;
    },
    paxName () {
      return  `${this.tr('Itinerary designed for')} ${this.$store.state.paxName}`;
    },
    image () {
      let img = this.coverImg;
      return {
        backgroundImage: "url(" + img + ")",
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
      };
    }
  },
  asyncComputed: {
    /*async image () {
      let img = await this.getImagePath('trip/img/hero.png');
      img = `${s3}/COVER.${this.bookingCode}.png`
      return {
      backgroundImage: "url(" + img + ")",
        'align-items': 'center',
        'background-attachment': 'fixed',
        'background-position': 'center',
      };
    }*/
  },
  methods: {
    async getImagePath (url) {
        let res = null;
        await Promise.resolve(import(`@/assets/${url}`)).then((value) => {
            res = value.default;
        });
        return res;
    },
    tr(key) {
      return this.$root.tr(key);
    }
  }

}
</script>
