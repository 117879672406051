<template>
    <swiper>
        <swiper-slide v-for="(image, index) of images" :key="image.id" class="h-96">
            <img :src="image.url" :class="imgClass" class="w-full h-full object-center object-cover">
            <Slide :index="index" :length="images.length"></Slide>
        </swiper-slide>
    </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import Slide from '@/components/itinerary/SwiperSlide.vue';
//import 'swiper/css';

export default {
    name: 'DaySwiper',
    props: {
        images: Array,
        imgClass: String
    },
    components: {
        Swiper,
        SwiperSlide,
        Slide,
    },
};
  </script>