<template>
    <footer class="py-10 md:py-12 bg-dark">
        <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap">
          <div class="w-full md:w-1/4 md:pr-12  mb-8 md:mb-0">
            <img src="@/assets/img/logo.svg" width="180px" class="mb-3 md:mb-3">
            <p class="text-white text-sm font-bold">Exploring our Nature and<br>culture since 1999</p>
          </div>
          <div class="w-full md:w-1/4 md:pr-12 mb-8 md:mb-0">
            <p class="text-white text-sm font-bold mb-2 md:mb-5">Argentina Authorized Travel Agency # 11320<br>Res. 1118/08</p>
            <p class="text-white text-sm font-normal">Thames 2062, Palermo<br>Buenos Aires, Argentina </p>
          </div>
          <div class="w-full md:w-1/4 md:pr-12 ml-auto ">
            <div class="flex items-center gap-4">
              <div class="w-4/12">
                <img :src="sellerImg" class="rounded-full  border-2 border-primary">
              </div>
              <div class="w-8/12 text-white">
                <p class="font-bold text-sm sm:text-lg">{{agentName}}</p>
                 <p class="text-xs md:text-sm uppercase">Travel Specialist</p>
                <div class="block">
                  <p class="text-sm"><img src="@/assets/img/wpp.svg" class="inline-block mr-2">{{ agentPhone }}</p>
                  <p class="text-sm "><a :href="agentMeet" target="_blank"><img src="@/assets/trip/img/meet.svg" class="inline-block mr-2">Reserve a meet</a></p>
                </div> 
                

              </div>
            </div>
          </div>
        </div>
    </footer>

</template>
<script>
export default {
  name: 'SayFooter',
  props: [],
  data () {
    return {
    }
  },
  computed: {
    sellerImg() {
      return this.$store.state.sellerImg;
    },
    agentName() {
      return this.$store.state.agentName;
    },
    agentMeet() {
      return this.$store.state.agentMeet;
    },
    agentPhone() {
      return this.$store.state.agentPhone;
    },

  }
}
</script>
